import Collapse from 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/carousel';
import ScrollSpy from 'bootstrap/js/dist/scrollspy';

window.addEventListener('DOMContentLoaded', () => {
    // Closes responsive menu when a scroll trigger link is clicked
    const scrollTriggers = document.body.querySelectorAll(".js-scroll-trigger");
    const navbarCollapse = document.body.querySelector(".navbar-collapse");

    scrollTriggers.forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault();

            new Collapse(navbarCollapse, { toggle: true });
        });
    });

    // Activate Bootstrap scrollspy on the main nav element
    const mainNav = document.body.querySelector('#mainNav');
    if (mainNav) {
        new ScrollSpy(document.body, {
            target: '#mainNav',
            offset: 74,
        })
    };
});